<template>
    <section>
        <b-sidebar id="sidebar-right-g" right shadow title="Typographie" :visible="visible">            
           <slot name="tt-editor">
           </slot>
        </b-sidebar>
    </section>
</template>
<script>
export default {
  name: "style-sidebar-g",
  components: {
    
  },
  props: {
    // block: Object,
    visible:{
      type:Boolean
    }
  },
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted() {

  },
};
</script>
<style scoped>
::v-deep #sidebar-right-g{
  width: 13rem;
  padding: 1rem;
}
</style>

